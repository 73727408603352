type LocalStorageKey = "token" | "refreshToken" | "username";

const keys: LocalStorageKey[] = ["token", "refreshToken", "username"];

const setLocalStorage = (key: LocalStorageKey, value: string) =>
  localStorage.setItem(key, value);
const getLocalStorage = (key: LocalStorageKey): string =>
  localStorage.getItem(key) || "";

const clearLocalStorage = () => {
  keys.forEach((key) => localStorage.setItem(key, ""));
};

export { setLocalStorage, getLocalStorage, clearLocalStorage };
