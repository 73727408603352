import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";
import { useAuth } from "./context/auth-context";
import { LoadingPage } from "./lib/LoadingPage";
const loadAuthenticatedApp = () => import("./authenticated-app");
const AuthenticatedApp = React.lazy(loadAuthenticatedApp);
const UnauthenticatedApp = React.lazy(() => import("./unauthenticated-app"));

const theme = createTheme({
  palette: {
    primary: {
      main: "#43a047",
      light: "#76d275",
      dark: "#00701a",
      contrastText: "#000000",
    },
    secondary: {
      main: "#a0439c",
      light: "#d373cd",
      dark: "#6f0d6d",
      contrastText: "#ffffff",
    },
  },
  typography: {
    htmlFontSize: 16,
    fontSize: 10,
  },
});

function App() {
  const { token } = useAuth();

  React.useEffect(() => {
    loadAuthenticatedApp();
  }, []);

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <React.Suspense fallback={<LoadingPage />}>
          {token ? <AuthenticatedApp /> : <UnauthenticatedApp />}
        </React.Suspense>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
