import queryString from 'query-string';
import {clearLocalStorage} from './localStorage'

type ClientConfig = {
  data?: any,
  customHeaders?: any,
  token: string,
  headers: any,
  query: any,
}


const client = async (endpoint: string, {data, customHeaders, token, headers, query, ...customConfig}: ClientConfig) => {
  const config = {
    method: data ? 'POST' : 'GET',
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      "Content-Type": data ? "application/json" : undefined,
      ...customHeaders
    },
    ...customConfig
  }

  const q = queryString.stringify(query) || "";
  return window.fetch(`${endpoint}${q ? "?" + q : ""}`, config)
    .then(async response => {
      if (response.status === 401) {
        clearLocalStorage()
        window.location.assign(window.location.href)
        return Promise.reject({message: "Bitte einloggen"});
      }
      if (response.status === 403) {
        (async () => {
          const message = await response.text();
          alert(`${response.statusText}: ${message}`);
        })();
        return Promise.reject({message: response.statusText});
      }
      const data = await response.json();
      if (response.ok) {
        return data;
      } else {
        return Promise.reject(data)
      }
    })
}

export default client
